<template>
      <el-dialog
        :title="'Send Email'"
        :visible="visible">
        <h5 style="text-align: center">Are you sure you want to send this campaign?</h5>

            <span slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="closeModal()">Cancel</button>&nbsp;
              <button class="btn btn-success" @click="sendEmail">Send</button>
            </span>
      </el-dialog>
</template>

<script>


import {Dialog} from "element-ui";
import FilterOrganizer from '../FilterShared/FilterOrganizer.vue';
import FgSelect from "../Inputs/formGroupSelect";


export default {
  components: {
    FgSelect,
      [Dialog.name]: Dialog,
      FilterOrganizer,
  },
  props: {
    visible: {
      type: Boolean,
      default: 0
    },
  },
    data() {
        return {
        }
    },
    methods: {
        sendEmail() {
          this.$emit('confirm');
        },
        reset: function () {
            for(let filter of this.filters){
                filter.value = null ;
            }
        },
        closeModal (name) {
          this.$emit('close');
        },
    }
}
</script>

