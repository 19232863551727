<template>

  <div class="row" ref="emailCampaignForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-12 col-sm-12">

                <div class="row">
                </div>

               <div class="row">
                 <div class="col-md-12">
                   <ValidationProvider
                           vid="title"
                           rules="required"
                           name="The Title"
                           v-slot="{ passed, failed,errors }">
                     <fg-input type="text"
                               :error="failed ? errors[0]: null"
                               label="Title"
                               name="title"
                               fou
                               v-model="formData.settings.title">
                     </fg-input>
                   </ValidationProvider>
                 </div>
                 <div class="col-md-12">
                   <ValidationProvider
                           vid="subject_line"
                           rules="required"
                           name="The Subject"
                           v-slot="{ passed, failed,errors }">
                     <fg-input type="text"
                               :error="failed ? errors[0]: null"
                               label="Subject"
                               name="subject_line"
                               fou
                               v-model="formData.settings.subject_line">
                     </fg-input>
                   </ValidationProvider>
                 </div>
               </div>
               <div class="row">
                 <div class="col-md-12">
                   <ValidationProvider
                           vid="from_name"
                           rules="required"
                           name="The From name"
                           v-slot="{ passed, failed,errors }">
                     <fg-input type="text"
                               :error="failed ? errors[0]: null"
                               label="From name"
                               name="from_name"
                               fou
                               v-model="formData.settings.from_name">
                     </fg-input>
                   </ValidationProvider>
                 </div>
               </div>
               <div class="row">
                 <div class="col-md-12">
                   <ValidationProvider
                           vid="reply_to"
                           rules="required"
                           name="The Reply to"
                           v-slot="{ passed, failed,errors }">
                     <fg-input type="text"
                               :error="failed ? errors[0]: null"
                               label="Reply to"
                               name="reply_to"
                               fou
                               v-model="formData.settings.reply_to">
                     </fg-input>
                   </ValidationProvider>
                 </div>
               </div>
                <ValidationProvider
                  vid="template_id"
                  rules="required"
                  name="The Template"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="template_id"
                    size="large"
                    filterable
                    clearable
                    placeholder="Templates"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="'Templates'"
                    :list="templateList"
                    :listItemLabel="'name'"
                    :listItemValue="'id'"
                    v-model="formData.settings.template_id">
                  </fg-select>
                </ValidationProvider>
                <ValidationProvider
                  vid="template_id"
                  rules="required"
                  name="The Template"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="list_id"
                    size="large"
                    filterable
                    clearable
                    placeholder="Parent"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="'Lists'"
                    :list="listsList"
                    :listItemLabel="'name'"
                    :listItemValue="'id'"
                    v-model="formData.recipients.list_id">
                  </fg-select>
                </ValidationProvider>
              </div>
            </div>

          </div>

          <div class="card-footer text-right">
            <l-button v-if="!editMode" :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit(true))"
                      nativeType="submit"
                      type="primary" wide>Save and send
            </l-button>
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit(false))"
                      nativeType="submit"
                      type="info" wide>Save
            </l-button>
            <l-button @click="$router.push('/email-campaigns/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Select, Option,Tooltip} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {ImageUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";
import {PrimeUploader} from '@/components';
import {PrimeVideoUploader} from '@/components';


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    FgSelect,
    LSwitch,
    PrimeUploader,
    ImageUploader,
    PrimeVideoUploader,
    'editor': Editor
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      editorConfig: this.getEditorConfig(),

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,

      formData: {
        settings:{
          title: "",
          subject_line: "",
          from_name: "",
          reply_to: "",
          template_id: "",
        },
        recipients:{
          list_id: '',
        },

      },
      templateList: [],
      listsList: [],

    };
  },

  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    })
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.emailCampaignForm
    });

    this.axios.post("email-campaigns/builder").then((response) => {
      this.templateList = response.data.templates;
      this.listsList = response.data.lists;

      this.id = this.$route.params['id'];

      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = "Edit Campaign";
        this.getCampaign();
      } else {
        this.editMode = false;
        this.formTitle = "Add Campaign";
        this.loader.hide();
      }
    }).catch((error) => {
      console.log(error)
      if (error.response.status === 400) {
        this.entityNotFoundError = true;
        this.$notify({
          message: "Api error",
          timeout: 2000,
          type: 'danger'
        });

      } else {
        console.error(error);
      }
    })
  },


  methods: {
    getCampaign() {
      let data = {
        'id' : this.id,
      };
      this.axios.post("email-campaigns/get",data).then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Campaign Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },


    submit(isSend) {
      let request;
      let successMessage;
      this.submitting = true;
      if (this.editMode === true) {
        request = this.axios.put("email-campaigns/update/" + this.id, this.formData);
        successMessage = "Campaign Updated Successfully";
      } else {
        this.formData.is_send = isSend;
        request = this.axios.post("email-campaigns/create", this.formData);
        successMessage = "Campaign Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/email-campaigns/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },

    getBannerImageInfo() {
      if(this.mediaInfo && this.mediaInfo["panel_image"]) {
        return this.mediaInfo["panel_image"];
      } else {
        return "";
      }
    },

  }
}
</script>
