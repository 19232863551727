<template>
  <div class="row">

    <div class="col-12">

      <card>

        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Email Campaign List</h4>
          <div class="d-flex justify-content-start">
            <div class="text-center pt-1 px-1">

              <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['email-campaigns/create'])"
                           :to="'/email-campaigns/create'"
                           class="btn btn-info btn-wd">
                Add New
                <span class="btn-label">
                      <i class="fa fa-plus"></i>
                  </span>
              </router-link>

            </div>
          </div>
        </div>

        <div>
          <general-data-table
            ref="table"
            :method="'POST'"
            :searchable="false"
            :api-url="'email-campaigns/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">


              <template slot-scope="scope">
                <div v-if="column.value === 'check'">
                  <l-switch
                    v-model="scope.row[column.value]">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>

                <span v-else>{{ scope.row[column.value] }}</span>

              </template>
            </el-table-column>

            <el-table-column
              :min-width="120"
              fixed="right"
              align="center"
              label="Actions">
              <template slot-scope="scope">
                <a v-if="$store.getters['auth/checkAccess']('email-campaigns/send-email')" v-tooltip.top-center="'Send email'"
                   class="btn-primary btn-simple btn-link"
                   @click="openSendEmailModal(scope.row.id)"><i class="fa fa-paper-plane"></i></a>
                <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['email-campaigns/update'])"
                             v-tooltip.top-center="'Edit'"
                             class="btn-warning btn-simple btn-link"
                             :to="'/email-campaigns/edit/'+scope.row.id">
                  <i class="fa fa-edit"></i></router-link>
                <a v-if="$store.getters['auth/checkAccess']('email-campaigns/delete')" v-tooltip.top-center="'Delete'"
                   class="btn-danger btn-simple btn-link"
                   @click="openDeleteModal(scope.row.id)"><i class="fa fa-times"></i></a>
                <a v-if="$store.getters['auth/checkAccess']('email-campaigns/duplicate')" v-tooltip.top-center="'Duplicate campaign'"
                   class="btn-default btn-simple btn-link"
                   @click="openDuplicateModal(scope.row.id)"><i class="fa fa-copy"></i></a>
              </template>
            </el-table-column>
          </general-data-table>

        </div>


      </card>

      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this campaigns?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>
      <send-email-modal :visible="sendEmailModalVisibility"
                        @close="closeSendEmailModal()"
                        @confirm="confirmSendEmailModal()">
      </send-email-modal>
      <duplicate-campaign :visible="duplicateModalVisibility"
                        @close="closeDuplicateModal()"
                        @confirm="confirmDuplicateModal()">
      </duplicate-campaign>
    </div>

  </div>
</template>
<script>
import {TableColumn} from 'element-ui'
import {NewPagination as NLPagination, Switch as LSwitch} from '@/components'
import {DeleteModal as DeleteModal} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import ExportModal from '@/components/ExportModal/ExportModal.vue';
import SendEmailModal from "../../../components/SendEmailModal/SendEmailModal";
import DuplicateCampaign from "../../../components/DuplicateCampaign/DuplicateCampaign";

export default {
  components: {
    DuplicateCampaign,
    SendEmailModal,
    NLPagination,
    DeleteModal,
    LSwitch,
    GeneralDataTable,
    [TableColumn.name]: TableColumn,
    ExportModal
  },

  data() {

    return {
      tableColumns: [
        {label: 'Title', value: "title", minWidth: '50', align: 'center'},
        {label: 'Type', value: 'type', minWidth: '50', align: 'center'},
        {label: 'Status', value: 'status', minWidth: '50', align: 'center'},
        {label: 'Emails sent', value: 'emails_sent', minWidth: '50', align: 'center'},
        {label: 'Send time', value: 'send_time', minWidth: '100', align: 'center'},
        {label: 'Recipients', value: 'list_name', minWidth: '50', align: 'center'},
        {label: 'Date', value: 'create_time', minWidth: '100', align: 'center'},
      ],
      modals: {
        basic: false,
        centered: false,
        custom: false,
        confirm: false
      },
      deleteModalVisibility: false,
      toDeleteId: undefined,
      sendEmailModalVisibility: false,
      toSendEmailId: undefined,
      duplicateModalVisibility: false,
      toDuplicateId: undefined,
      url : process.env.VUE_APP_SERVER_IMAGE_URL,

    }

  },
  methods: {

    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },
    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },
    confirmDeleteModal() {

      let data = {
          'id': this.toDeleteId,
      }
      this.axios.delete("email-campaigns/delete", {headers: {}, data}).then((response) => {

        this.$notify({
          message: "Campaign deleted successfully",
          timeout: 1000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeDeleteModal();
      this.toDeleteId = undefined;
    },
    openModal (name) {
      this.modals[name] = true
    },
    closeModal (name) {
      this.modals[name] = false
    },
    openSendEmailModal(id) {
      this.sendEmailModalVisibility = true;
      this.toSendEmailId = id;
    },
    closeSendEmailModal() {
      this.sendEmailModalVisibility = false;
    },
    confirmSendEmailModal() {
      console.log(this.toSendEmailId)
      let data = {
        'id': this.toSendEmailId,
      }
      this.axios.post("email-campaigns/send-email", data).then((response) => {

        this.$notify({
          message: "Campaign sent successfully",
          timeout: 1000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeSendEmailModal();
      this.toSendEmailId = undefined;
    },


    openDuplicateModal(id) {
      this.duplicateModalVisibility = true;
      this.toDuplicateId = id;
    },
    closeDuplicateModal() {
      this.duplicateModalVisibility = false;
    },
    confirmDuplicateModal() {
      let data = {
        'id': this.toDuplicateId,
      }
      this.axios.post("email-campaigns/duplicate", data).then((response) => {

        this.$notify({
          message: "Campaign duplicated successfully",
          timeout: 1000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeDuplicateModal();
      this.toSendEmailId = undefined;
    },
  },
}
</script>

