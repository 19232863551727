var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"emailCampaignForm",staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-md-12 col-sm-12"},[_c('ValidationObserver',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.formTitle)+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-sm-12"},[_c('div',{staticClass:"row"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"title","rules":"required","name":"The Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Title","name":"title","fou":""},model:{value:(_vm.formData.settings.title),callback:function ($$v) {_vm.$set(_vm.formData.settings, "title", $$v)},expression:"formData.settings.title"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"subject_line","rules":"required","name":"The Subject"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Subject","name":"subject_line","fou":""},model:{value:(_vm.formData.settings.subject_line),callback:function ($$v) {_vm.$set(_vm.formData.settings, "subject_line", $$v)},expression:"formData.settings.subject_line"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"from_name","rules":"required","name":"The From name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"From name","name":"from_name","fou":""},model:{value:(_vm.formData.settings.from_name),callback:function ($$v) {_vm.$set(_vm.formData.settings, "from_name", $$v)},expression:"formData.settings.from_name"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"reply_to","rules":"required","name":"The Reply to"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Reply to","name":"reply_to","fou":""},model:{value:(_vm.formData.settings.reply_to),callback:function ($$v) {_vm.$set(_vm.formData.settings, "reply_to", $$v)},expression:"formData.settings.reply_to"}})]}}],null,true)})],1)]),_c('ValidationProvider',{attrs:{"vid":"template_id","rules":"required","name":"The Template"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-select',{attrs:{"name":"template_id","size":"large","filterable":"","clearable":"","placeholder":"Templates","error":failed ? errors[0]: null,"input-classes":'select-default',"label":'Templates',"list":_vm.templateList,"listItemLabel":'name',"listItemValue":'id'},model:{value:(_vm.formData.settings.template_id),callback:function ($$v) {_vm.$set(_vm.formData.settings, "template_id", $$v)},expression:"formData.settings.template_id"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"template_id","rules":"required","name":"The Template"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-select',{attrs:{"name":"list_id","size":"large","filterable":"","clearable":"","placeholder":"Parent","error":failed ? errors[0]: null,"input-classes":'select-default',"label":'Lists',"list":_vm.listsList,"listItemLabel":'name',"listItemValue":'id'},model:{value:(_vm.formData.recipients.list_id),callback:function ($$v) {_vm.$set(_vm.formData.recipients, "list_id", $$v)},expression:"formData.recipients.list_id"}})]}}],null,true)})],1)])]),_c('div',{staticClass:"card-footer text-right"},[(!_vm.editMode)?_c('l-button',{attrs:{"disabled":_vm.entityNotFoundError || _vm.submitting,"nativeType":"submit","type":"primary","wide":""},on:{"click":function($event){$event.preventDefault();handleSubmit(_vm.submit(true))}}},[_vm._v("Save and send ")]):_vm._e(),_c('l-button',{attrs:{"disabled":_vm.entityNotFoundError || _vm.submitting,"nativeType":"submit","type":"info","wide":""},on:{"click":function($event){$event.preventDefault();handleSubmit(_vm.submit(false))}}},[_vm._v("Save ")]),_c('l-button',{attrs:{"type":"danger","wide":""},on:{"click":function($event){return _vm.$router.push('/email-campaigns/list')}}},[_vm._v("Cancel ")])],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }